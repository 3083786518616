<template>
  <div class="search_container">
    <div class="box">
      <div class="ops">
        <div @click="goBack" class="back_btn">返回</div>
        <div>
          <el-select v-model="city" @change="citySelected">
            <el-option label="--所有市州--" value="all"></el-option>
            <el-option
              v-for="item in cityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="district" @change="districtSelected">
            <el-option label="--所有区县--" value="all"></el-option>
            <el-option
              v-for="item in districtList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="cn_title"></div> -->
      <div class="en_title">
        共<span style="color: #3bb573">{{ total }}</span
        >条搜索结果
      </div>
      <div class="line"></div>
      <div class="card_list">
        <el-card class="card" v-for="item in activities" :key="item.id">
          <div
            class="img_content"
            :style="{ backgroundImage: 'url(' + item.cover + ')' }"
            @click="goDetail(item.id)"
          >
            <!-- <img :src="item.cover" alt=""> -->
          </div>
          <div class="text_content" @click="goDetail(item.id)">
            <div class="title">{{ item.title }}</div>
            <div class="dec">{{ item.describes }}</div>
          </div>
        </el-card>
      </div>
      <!-- <div class="more_info">更多精彩活动尽请期待～</div> -->
      <div class="block" style="margin-top: 1.6rem">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          background
          :page-size="limit"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-backtop class="backtop" style="width: 1rem" :right="10" :bottom="50">
      <div
        style="
           {
            height: 100%;
            width: 100%;
          }
        "
      >
        <img style="width: 100%; height: 100%" src="@/assets/img/toTop.png" />
        <div style="text-align: center; font-size: 14px" class="goTop">
          回顶部
        </div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import cityData from '@/utils/sichuan.json'
export default {
  name: 'activity',
  data() {
    return {
      cityList: cityData,
      districtList: [],
      city: 'all',
      district: 'all',
      activities: [],
      keyWord: '',
      limit: 15,
      page: 1,
      total: 0
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    goBack() {
      // this.$router.push({ path: '/' })
      this.$router.go(-1)
    },
    // 跳转至详情页
    goDetail(val) {
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    getList() {
      let url =
        '/api/web_index/search?keyWord=' +
        this.keyWord +
        '&limit=' +
        this.limit +
        '&page=' +
        this.page
      if (this.city !== 'all') {
        let city = this.city
        if (this.district !== 'all') {
          city += ',' + this.district
        }
        url += '&city=' + encodeURIComponent(city)
      }
      this.$axios.get(url).then((res) => {
        console.log('95--->', res.data)
        const a = res.data
        this.activities = a.records
        this.total = a.total
        document.body.scrollTop = document.documentElement.scrollTop = 750
      })
    },
    citySelected(val) {
      const query = { keyWord: this.keyWord }
      if (val !== 'all') {
        query.city = val
      }
      this.$router.push({
        path: '/search',
        query: query
      })
    },
    districtSelected(val) {
      const query = { keyWord: this.keyWord }
      if (this.city !== 'all') {
        query.city = this.city
      }
      if (val !== 'all') {
        query.city += ',' + val
      }
      this.$router.push({
        path: '/search',
        query: query
      })
    },
    handleCityQuery(cityParam) {
      if (cityParam) {
        const parArr = cityParam.split(',')
        const cityVal = parArr[0]
        const districtVal = parArr[1]
        const city = this.cityList.find((item) => item.value === cityVal)
        if (city == null) {
          this.city = 'all'
          this.district = 'all'
          this.districtList = []
        } else {
          this.city = cityVal
          this.districtList = city.children || []
          const district = this.districtList.find(
            (item) => item.value === districtVal
          )
          if (district == null) {
            this.district = 'all'
          } else {
            this.district = districtVal
          }
        }
      } else {
        this.city = 'all'
        this.district = 'all'
      }
    }
  },
  mounted() {
    this.keyWord = this.$route.query.keyWord
    this.handleCityQuery(this.$route.query.city)
    this.getList()
  },
  watch: {
    '$route.query.keyWord'() {
      this.keyWord = this.$route.query.keyWord
      this.getList()
    },
    '$route.query.city'() {
      const cityParam = this.$route.query.city
      this.handleCityQuery(cityParam)
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.search_container {
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
}
.back_btn {
  margin: 0.3rem 0.5rem;
  margin-top: 0.8rem;
  font-size: 0.2rem;
  padding: 0.1rem 0.3rem;
  border-radius: 0.8rem;
  border: 0.01rem solid #3bb573;
  color: #3bb573;
  background: transparent;
  cursor: pointer;
  font-family: 'MicrosoftYaHei';
  width: 1.6rem;
  text-align: center;
  letter-spacing: 2px;
}
.ops {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0.5rem;
  > div:last-child {
    display: flex;
    gap: 5px;
    .el-input,
    .el-input__inner {
      width: 3.5rem;
    }
  }
}
.box {
  // margin-left: 11.8%;
  // margin-right: 11.8%;
  background: #f4f4f4;
  border-radius: 30px;
  //   padding-top: 2rem;
  padding-bottom: 2.4rem;
  .cn_title {
    text-align: center;
    font-size: 0.8rem;
    font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
    font-weight: 700;
    color: #333333;
    line-height: 0.8rem;
    height: 0.8rem;
    letter-spacing: 2px;
  }
  .en_title {
    text-align: center;
    margin-top: 0.6rem;
    height: 0.4rem;
    opacity: 1;
    font-size: 0.4rem;
    font-family: 'SimHei';
    color: #666666;
    line-height: 0.4rem;
    letter-spacing: 2px;
  }
  .line {
    // width: 80%;
    height: 1px;
    background-color: #e6e6e6;
    // margin-left: 10%;
    margin-top: 1.6rem;
  }
  .card_list {
    padding: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;
    .card {
      width: 30%;
      margin-right: 5%;
      margin-bottom: 1rem;
      padding-bottom: 0.2rem;
      // display: flex;
      border-radius: 0.2rem;
      // flex-direction: column;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .el-card__body {
        padding: 0;
      }
      .img_content {
        height: 4rem;
        background-size: cover;
        width: 100%;
      }
      .text_content {
        background: #fff;
        .title {
          margin: 0.1rem 0;
          text-align: center;
          font-size: 0.4rem;
          font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
          font-weight: 700;
          color: #333333;
        }
        .dec {
          padding: 0 0.4rem;
          font-size: 0.28rem;
          font-family: 'SimHei';
          text-align: left;
          color: #333333;
          line-height: 0.4rem;
          letter-spacing: 2px;
          margin-bottom: 0.15rem;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .more_info {
    opacity: 1;
    font-size: 0.4rem;
    font-family: 'SimHei';
    text-align: center;
    color: #666666;
    letter-spacing: 2px;
  }
  .backtop {
    right: calc(10% + 1rem) !important;
  }
  .goTop {
    height: 14px;
    opacity: 1;
    font-size: 14px !important;
    font-family: 'MicrosoftYaHei';
    text-align: center;
    font-weight: 700;
    color: #333333;
    line-height: 14px;
  }
  .block {
    text-align: center;
  }
}
@media screen and (max-width: 1600px) {
  .right_box {
    width: 70px !important;
  }
  .btn {
    height: 28px !important;
    border-radius: 16px !important;
    font-size: 12px !important;
    line-height: 28px !important;
  }
}
</style>
